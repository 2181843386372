import React, { useEffect } from 'react'

function isClient() {
  return typeof window === 'object'
}

const EmptyPage = () => {
  useEffect(() => {
    if (isClient()) {
      window.location.href = 'https://www.iubenda.com/privacy-policy/13414332'
    }
  }, [])

  return <div />
}

export default EmptyPage
